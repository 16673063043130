/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/sites';
import * as selectors from 'data/selectors/sites';
import { cloneDeep } from 'lodash-es';
import * as Sentry from '@sentry/react';

import transformDevices from 'data/utils/devices-transform';

import { v4 as uuidv4 } from 'uuid';


export const fetchList =
  (page, perPage, groupId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get('api/v2/sites/', { params: { page, perPage, group_id: groupId } });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.site]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
      return e;
    }
  };

export const fetchSingle =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/sites/${id}/`);
      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.site),
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchStatus =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.STATUS_LOAD_START });
    try {
      const res = await api.get(`/sites/${id}/status/`);
      dispatch({
        type: constants.STATUS_LOAD_SUCCESS,
        payload: normalize(res.data, schema.siteStatus),
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.STATUS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const filterGroup = (group) => ({ type: constants.FILTER_GROUP, payload: group });

export const filterStatus = (status) => ({ type: constants.FILTER_STATUS, payload: status });

export const filterType = (type) => ({ type: constants.FILTER_TYPE, payload: type });

export const filterSearch = (search) => ({ type: constants.FILTER_SEARCH, payload: search });

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });

export const clearSingleStatus = () => ({ type: constants.CLEAR_SINGLE_STATUS });

export const clearStructure = () => ({ type: constants.CLEAR_STRUCTURE });

// TODO: use sockets
export const backgroundFetch =
  (page, perPage) =>
  async (dispatch, getState, { api, schema, normalize }) => {
    if (selectors.getItemsListLoading(getState())) return;
    try {
      const res = await api.get('/sites/', { params: { page, perPage } });
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload: normalize(res.data, [schema.site]),
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      throw e;
    }
  };

export const create =
  (values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.CREATION_START });
    actions.setSubmitting(true);
    try {
      const res = await uploadChunks(`/api/v3/sites/`, api.post, values);
      if (res.status !== 201) throw new Error(res.statusText);

      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus('success');
      dispatch({
        type: constants.CREATION_SUCCESS,
        payload: normalize(res.data, schema.site),
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.CREATION_FAILED });
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return e.response.data;
    }
  };

export const update =
  (id, values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.UPDATE_START });
    actions.setSubmitting(true);
    try {
      const valueToSend = cloneDeep(values);
      transformDevices(valueToSend.houses, `houses`, valueToSend);

      const res = await uploadChunks(`/api/v3/sites/${id}/`, api.patch, valueToSend);
      if (res.status !== 200) throw new Error(res.statusText);

      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus('success');
      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: normalize(res.data, schema.site),
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.UPDATE_FAILED });
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return e.response.data;
    }
  };

export const fetchStructure =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.STRUCTURE_LOAD_START });
    try {
      const res = await api.get(`/sites/${id}/structure/`);
      dispatch({
        type: constants.STRUCTURE_LOAD_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.STRUCTURE_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchLatestInfo =
  (id, type) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.LATEST_INFO_LOAD_START });
    try {
      const res = await api.get(`/sites/${id}/latest/`, { params: { type } });

      const payload = {
        id,
        type,
        data: res.data,
      };

      dispatch({
        type: constants.LATEST_INFO_LOAD_SUCCESS,
        payload,
      });

      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.LATEST_INFO_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const setSiteRecommendationsCount = (siteId, count) => (dispatch) => {
  dispatch({ type: constants.UPDATE_SITE_RECOMMENDATIONS_COUNT, payload: { siteId, count } });
};

export const fetchInvitations =
  (site) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.INVITATIONS_LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/invitations/`);

      dispatch({
        type: constants.INVITATIONS_LOAD_SUCCESS,
        payload: res.data.items,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.INVITATIONS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const uploadChunks = async (endpoint, send_method, values, chunkSize = 1024) => {
  const serializedData = JSON.stringify(values);
  const totalChunks = Math.ceil(serializedData.length / chunkSize);
  const messageId = "site-" + values["name"] + "-" + uuidv4()
  let res = ""

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const chunkStart = chunkIndex * chunkSize;
    const chunkEnd = chunkStart + chunkSize;

    const chunk = serializedData.slice(chunkStart, chunkEnd);
    const payload = {
      chunk,
      chunk_index: chunkIndex,
      total_chunks: totalChunks,
      message_id: messageId,
    };
    res = await send_method(endpoint, payload);
  }

  return res
};
