/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/sites';
import { cloneDeep } from 'lodash-es';
import * as Sentry from '@sentry/react';

import { saveBlob } from 'data/utils/download';

import transformDevices from 'data/utils/devices-transform';

import { v4 as uuidv4 } from 'uuid';

export const fetchList =
  (page = 1, perPage = 10, orderingField, order, displayStatus = 'USE', country, supplier, search) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/`, {
        params: {
          page,
          perPage,
          ordering_field: orderingField,
          order,
          country,
          supplier,
          display_status: displayStatus,
          search,
        },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.adminSite]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.adminSite),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchStats =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.STATS_LOAD_START });
    try {
      const res = await api.get('/api/v2/admin-dashboard/sites/stats/');

      dispatch({
        type: constants.STATS_LOAD_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.STATS_LOAD_FAILED });

      return e;
    }
  };

export const fetchAvailability =
  (id, sensor, timeframe) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.AVAILABILITY_LOAD_START });
    try {
      const { from, to } = timeframe;

      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/availability/`, {
        params: { sensor, start_date: from, end_date: to, site_pk: id },
      });

      dispatch({
        type: constants.AVAILABILITY_LOAD_SUCCESS,
        payload: normalize({ id, data: res.data.data }, schema.availability),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.AVAILABILITY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchDevices =
  (id, page = 1, perPage = 1000, orderingField, order) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.DEVICE_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/devices/`, {
        params: { page, perPage, ordering_field: orderingField, order },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.device]),
      };

      dispatch({ type: constants.DEVICE_LOAD_SUCCESS, payload });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DEVICE_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchDataUsage =
  (id, timeframe) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.DATA_USAGE_LOAD_START });
    try {
      const { from, to } = timeframe;
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/gateways/data-usage/`, {
        params: { start_date: from, end_date: to },
      });

      dispatch({ type: constants.DATA_USAGE_LOAD_SUCCESS, payload: normalize(res.data, schema.dataUsage) });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DATA_USAGE_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchGateways =
  (id, page = 1, perPage = 1000, orderingField, order) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.GATEWAYS_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/gateways/`, {
        params: { page, perPage, ordering_field: orderingField, order },
      });

      dispatch({ type: constants.GATEWAYS_LOAD_SUCCESS, payload: res.data });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.GATEWAYS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchUsersActivity =
  (id, timeframe) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.USERS_ACTIVITY_LOAD_START });
    try {
      const { from, to } = timeframe;

      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/users/login-activity/`, {
        params: { start_date: from, end_date: to },
      });

      dispatch({
        type: constants.USERS_ACTIVITY_LOAD_SUCCESS,
        payload: normalize(res.data, schema.loginActivity),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.USERS_ACTIVITY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchWeeklyDataUsage =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.WEEKLY_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/gateways/data-usage/total`, { params: { timeframe: 7 } });

      dispatch({
        type: constants.WEEKLY_LOAD_SUCCESS,
        payload: normalize(res.data, schema.dataUsage),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.WEEKLY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchMonthlyDataUsage =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.MONTHLY_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/gateways/data-usage/total`, {
        params: { timeframe: 30 },
      });

      dispatch({
        type: constants.MONTHLY_LOAD_SUCCESS,
        payload: normalize(res.data, schema.dataUsage),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.MONTHLY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchNotifications =
  (id, page = 1, perPage = 10, orderingField = 'time', order = 'descending') =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.NOTIFICATIONS_LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/notification/`, {
        params: {
          page,
          perPage,
          type: 'NORMAL',
          ordering_field: orderingField,
          order,
        },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.notification]),
      };

      dispatch({
        type: constants.NOTIFICATIONS_LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.NOTIFICATIONS_LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchAlerts =
  (id, page = 1, perPage = 10, filter, sensorFilter, orderingField = 'time', order = 'descending') =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.ALERTS_LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/notification/`, {
        params: {
          page,
          perPage,
          type: 'ALERT',
          alarm_filter: filter,
          sensor_filter: sensorFilter,
          ordering_field: orderingField,
          order,
        },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.alert]),
      };

      dispatch({
        type: constants.ALERTS_LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.ALERTS_LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchPushNotifications =
  (id, page, perPage, airspaceId, orderingField, order) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/push-notifications/`, {
        params: {
          page,
          perPage,
          airspace_id: airspaceId,
          ordering_field: orderingField,
          order,
        },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.pushNotification]),
      };

      dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS, payload });
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      dispatch({ type: constants.PUSH_NOTIFICATIONS_LIST_LOAD_FAILED });
    }
  };

export const updateSiteInfo =
  (values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.INFO_UPDATE_START });

    actions.setSubmitting(true);
    try {
      const { id } = values;
      const res = await api.patch(`/api/v2/admin-dashboard/sites/${id}/`, values);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({
        type: constants.INFO_UPDATE_SUCCESS,
        payload: normalize(res.data, schema.adminSite),
      });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      dispatch({ type: constants.INFO_UPDATE_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data.message });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const updateDisplayStatus =
  (id, value) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DISPLAY_STATUS_UPDATE_START });
    try {
      const res = await api.patch(`/api/v2/admin-dashboard/sites/${id}/change-status/`, { display_status: value });

      dispatch({
        type: constants.DISPLAY_STATUS_UPDATE_SUCCESS,
      });
      return res;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DISPLAY_STATUS_UPDATE_FAILED });
      Sentry.captureException(e);
      return false;
    }
  };

export const fetchStatus =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.STATUS_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/status/`);
      dispatch({
        type: constants.STATUS_LOAD_SUCCESS,
        payload: normalize(res.data, schema.siteStatus),
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.STATUS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchStructure =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.STRUCTURE_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/structure/`);
      dispatch({
        type: constants.STRUCTURE_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.STRUCTURE_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchTemperatureHumidity =
  (id, sensorsArray, timeframe, devicesArray) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.SITE_TEMPERATURE_HUMIDITY_LOAD_START });
    try {
      const devices = devicesArray.map((device) => device.id).join(',');
      const sensors = sensorsArray.join(',');
      const { from, to } = timeframe;
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/sensor-data/`, {
        params: { sensors, start_date: from, end_date: to, devices },
      });
      dispatch({
        type: constants.SITE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS,
        payload: res.data,
      });

      return true;
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.SITE_TEMPERATURE_HUMIDITY_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchRDI =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RDI_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/rdi/`, { params: { timeframe: 3 } });

      dispatch({
        type: constants.RDI_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RDI_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchAirspacesStatuses =
  (sitePk, airspaces, timeframe, sensor) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.AIRSPACES_STATUSES_LOAD_START });
    try {
      const { from, to } = timeframe;

      const res = await Promise.all(
        airspaces.map((airspace) =>
          api
            .get(`/api/v2/admin-dashboard/sites/${sitePk}/airspaces/${airspace.get('id')}/devices/data`, {
              params: { start_date: from, end_date: to, sensor },
            })
            .then((response) => response.data),
        ),
      );

      dispatch({
        type: constants.AIRSPACES_STATUSES_LOAD_SUCCESS,
        payload: res,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.AIRSPACES_STATUSES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchUsers =
  (id, page = 1, perPage = 10, orderingField, order) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.USERS_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${id}/users/`, {
        params: { page, perPage, ordering_field: orderingField, order },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.user]),
      };

      dispatch({
        type: constants.USERS_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.USERS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const update =
  (id, values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.UPDATE_START });
    actions.setSubmitting(true);
    try {
      const valueToSend = cloneDeep(values);
      transformDevices(valueToSend.houses, `houses`, valueToSend);

      const res = await uploadChunks(`/api/v3/admin-dashboard/sites/${id}/edit/`, api.patch, valueToSend);
      if (res.status !== 200) throw new Error(res.statusText);

      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus('success');
      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: normalize(res.data, schema.site),
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.UPDATE_FAILED });
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return e.response.data;
    }
  };

export const activateDeviceLicense =
  (sitePk, devicePk) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.ACTIVATE_DEVICE_LICENSE_START });

    try {
      await api.patch(`/sites/${sitePk}/devices/licenses/${devicePk}/activate`);

      dispatch({
        type: constants.ACTIVATE_DEVICE_LICENSE_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      dispatch({ type: constants.ACTIVATE_DEVICE_LICENSE_FAILED });

      Sentry.captureException(e);
      return false;
    }
  };

export const downloadList =
  (fileType, country, supplier, displayStatus, search) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.LIST_DOWNLOAD_START });
    try {
      const res = await api.get('/api/v2/admin-dashboard/sites/download/', {
        params: { file_type: fileType, country, supplier, display_status: displayStatus, search },
        responseType: 'arraybuffer',
      });

      dispatch({
        type: constants.LIST_DOWNLOAD_SUCCESS,
      });

      saveBlob(res.data, 'sites-list', fileType);

      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.LIST_DOWNLOAD_FAILED });

      return e;
    }
  };

export const fetchDiseaseHistory =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DISEASE_HISTORY_LOAD_START });
    try {
      const res = await api.get(`/sites/disease-histories/`);

      dispatch({
        type: constants.DISEASE_HISTORY_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DISEASE_HISTORY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const uploadChunks = async (endpoint, send_method, values, chunkSize = 1024) => {
  const serializedData = JSON.stringify(values);
  const totalChunks = Math.ceil(serializedData.length / chunkSize);
  const messageId = "site-" + values["name"] + "-" + uuidv4()
  let res = ""

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const chunkStart = chunkIndex * chunkSize;
    const chunkEnd = chunkStart + chunkSize;

    const chunk = serializedData.slice(chunkStart, chunkEnd);
    const payload = {
      chunk,
      chunk_index: chunkIndex,
      total_chunks: totalChunks,
      message_id: messageId,
    };
    res = await send_method(endpoint, payload);
  }

  return res
};


export const infoClear = () => ({ type: constants.INFO_CLEAR });

export const dataUsageClear = () => ({ type: constants.DATA_USAGE_CLEAR });

export const availabilityClear = () => ({ type: constants.AVAILABILITY_CLEAR });

export const clearSingleStatus = () => ({ type: constants.CLEAR_SINGLE_STATUS });

export const clearStructure = () => ({ type: constants.CLEAR_STRUCTURE });

export const clearTemperatureHumidity = () => ({ type: constants.CLEAR_SITE_TEMPERATURE_HUMIDITY });

export const clear = () => ({ type: constants.CLEAR });

export const rdiClear = () => ({ type: constants.RDI_CLEAR });

export const clearCharts = () => ({ type: constants.CHARTS_CLEAR });

export const clearAirspacesStatuses = () => ({ type: constants.AIRSPACES_STATUSES_CLEAR });

export const clearUsers = () => ({ type: constants.USERS_CLEAR });

export const clearStats = () => ({ type: constants.CLEAR_STATS });
